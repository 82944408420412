import React from 'react';

import classes from './style/message.module.scss'; 

export default function Message({ message, style }) {
    return (message &&
        <div className={classes[message.text ? "message-open" : "message-close"]} style={style}>
            <span className={classes[message.type ? "success-text" : "error-text"]}>
                {message.text}
            </span>
        </div>
    );
}
