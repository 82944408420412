import React, { useEffect, useState } from 'react';

import { Table, Space, Button, Modal, notification, Input } from 'antd';

import { SearchOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { REQUESTS } from '../../api/requests';

import dateFormat from '../../utils/dateFormat';

import SubresellersModal from './components/SubresellersModal';

import ResellerModal from './components/ResellerModal';

import styles from "./index.module.scss"

const { confirm } = Modal;

export default function Subresellers() {
  const [data, setData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);

  const [total, setTotal] = useState(0);

  const [limit, setLimit] = useState(10);

  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);

  const [actionType, setActionType] = useState("add");

  const [editableItem, setEditableItem] = useState(null);

  const [sort, setSort] = useState(["createdAt", "DESC"]);

  const [search, setSearch] = useState({});

  const [subReseller, setSubReseller] = useState(null);

  const [api, contextHolder] = notification.useNotification();

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      render: (text, record, index) => {
        return (currentPage - 1) * limit + index + 1
      }
    },
    {
      title: 'Name',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Sub-resellers',
      dataIndex: 'sub_reseller_count',
      key: 'sub_reseller_count',
      sorter: true,
    },
    {
      title: 'Devices',
      dataIndex: 'device_count',
      key: 'device_count',
      sorter: true,
    },
    {
      title: 'Created date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_text, record) => dateFormat(new Date(record.createdAt)),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        return <Space>
          <Button
            type="primary"
            size='small'
            onClick={(e) => {
              e.stopPropagation();
              handleTableActionChange("edit", record)
            }}>
            <EditOutlined /></Button>
          <Button
            type="primary"
            size='small'
            onClick={(e) => {
              e.stopPropagation();
              handleTableActionChange("delete", record)
            }} danger>
            <DeleteOutlined />
          </Button>
        </Space>
      },
    },
  ];

  const handleTableActionChange = (actionType, item) => {
    switch (actionType) {
      case "edit":
        setActionType("edit");
        setOpen(true);
        setEditableItem(item);
        break;
      case "delete":
        confirm({
          title: 'Do you want to delete?',
          icon: null,
          onOk() {
            deleteSubReseller(item.id);
          }
        });
        break;
      default:
        break;
    }
  }

  const handleTableChange = (pagination, filters, sorter) => {
    setLoading(true);

    setCurrentPage(pagination.current);

    setLimit(pagination.pageSize);

    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }
  }

  const deleteSubReseller = (id) => {
    REQUESTS.SUBRESELLERS.DELETE({ id }, () => {
      getResellers();
      api['success']({
        message: "Sub-resellers",
        description: "Updated"
      });
    })
  }

  const getResellers = () => {
    setLoading(true);

    const query = {
      page: currentPage,
      limit,
      count: total,
      sort,
    };

    if (search.length) {
      query['search'] = {
        username: search,
        email: search,
      }
    }

    REQUESTS.SUBRESELLERS.GET(
      { query: JSON.stringify(query) },
      (data) => {
        setLoading(false);
        setData(data.rows);
        setTotal(data.count);
        setLimit(data.limit);

      },
      (err) => setLoading(false))
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getResellers();
    }, 500);

    return () => clearTimeout(timeoutId);

  }, [limit, currentPage, sort, search])

  return (
    <div>
      <h1>Sub-resellers</h1>
      <Button
        onClick={() => {
          setOpen(true);
          setActionType("add");
        }}
        className={'add-button'}
      >
        Add New
      </Button>
      <div style={{ width: 200 }}>
        <Input
          placeholder='Search'
          suffix={<SearchOutlined />}
          const={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <Table
        columns={columns}
        dataSource={data}
        onChange={handleTableChange}
        loading={loading}
        pagination={{
          position: ["bottomCenter"],
          current: currentPage,
          total: total,
          pageSize: limit,
          showSizeChanger: true,
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              setSubReseller(record);
            },
          };
        }}
        rowClassName={styles['table-row-style']}
        bordered
        size='small'
        scroll={{ x: '100%' }}
      />
      <ResellerModal
        open={open}
        handleCancel={() => {
          setOpen(false);
          setActionType("add");

          if (editableItem) {
            setEditableItem(null);
          }

        }}

        editableItem={editableItem}
        actionType={actionType}
        getResellers={getResellers}
      />
      <SubresellersModal
        subReseller={subReseller}
        setSubReseller={setSubReseller}
      />
      {contextHolder}
    </div>
  )
}
