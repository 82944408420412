import React, { useEffect, useState } from 'react';

import { Table, Space, Button, Modal, notification, Tag } from 'antd';

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { REQUESTS } from '../../api/requests';

import UsersModal from './components/UsersModal';

import ValidateMacAddress from '../../components/ValidateMacAddress';

const { confirm } = Modal;

export default function Users() {

  const [data, setData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);

  const [total, setTotal] = useState(0);

  const [limit, setLimit] = useState(10);

  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);

  const [actionType, setActionType] = useState("");

  const [current, setCurrent] = useState(null);

  const [sort, setSort] = useState(["createdAt", "DESC"]);

  const [searchMac, setSearchMac] = useState("");

  const [search, setSearch] = useState({});

  const [api, contextHolder] = notification.useNotification();

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      render: (text, record, index) => {
        return (currentPage - 1) * limit + index + 1
      }
    },
    {
      title: 'Mac Address',
      dataIndex: 'mac',
      key: 'mac',
    },
    {
      title: 'App name',
      dataIndex: 'app_name',
      key: 'app_name',
      render: (_text, record) => record?.app_name || "-"
    },
    {
      title: 'Platform',
      dataIndex: 'platform',
      key: 'platform',
      filters: [
        {
          text: 'Tizen',
          value: 'tizen',
        },
        {
          text: 'Webos',
          value: 'webos',
        },
        {
          text: 'Android',
          value: 'android',
        },
        {
          text: 'Vidaa',
          value: 'vidaa',
        },
        {
          text: 'Foxxum',
          value: 'foxxum',
        },
        {
          text: 'Zessen',
          value: 'zessen',
        },
        {
          text: 'Vizio',
          value: 'vizio',
        },
        {
          text: 'Tvos',
          value: 'tvos',
        },
        {
          text: 'IOS',
          value: 'ios',
        },
        {
          text: 'Roku',
          value: 'roku',
        },
      ],
      filterMultiple: false,
    },
    {
      title: 'Status',
      dataIndex: 'is_online',
      key: 'is_online',
      filters: [
        {
          text: 'Online',
          value: true,
        },
        {
          text: 'Offline',
          value: false,
        },
      ],
      filterMultiple: false,
      render: (_text, record) => <Tag color={record?.is_online ? "green" : "#808080b3"} >
        {record?.is_online ? "Online" : "Offline"}
      </Tag>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        return <Space>
          <Button
            type="primary"
            size='small'
            onClick={() => handleTableActionChange("edit", record)}>
            <EditOutlined /></Button>
          <Button
            type="primary"
            size='small'
            onClick={() => handleTableActionChange("delete", record)} danger>
            <DeleteOutlined />
          </Button>
        </Space>
      },
    },
  ];

  const deleteUser = (id) => {
    REQUESTS.USERS.DELETE({ id }, (data) => {
      getUsers();
      api['success']({
        message: "Users",
        description: "Updated"
      });
    })
  }

  const handleTableActionChange = (actionType, item) => {
    switch (actionType) {
      case "edit":
        setOpen(true);
        setActionType("edit");
        setCurrent(item.mac);
        break;
      case "delete":
        confirm({
          title: 'Do you want to delete?',
          icon: null,
          onOk() {
            deleteUser(item.id)
          }
        });
        break;
      default:
        break;
    }
  }

  const handleTableChange = (pagination, filters, sorter) => {
    setLoading(true);

    setCurrentPage(pagination.current);

    setLimit(pagination.pageSize);

    setSearch(filters);

    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }
  }

  const getUsers = () => {

    setLoading(true);

    const query = {
      page: currentPage,
      limit,
      count: total,
      sort,
    };

    if (searchMac.length) {
      query['search'] = {};
      query.search['mac'] = searchMac;
    }

    if (search.platform || search.is_online) {
      query.filter = {};
      if (search.platform) {
        query.filter['platform'] = search.platform[0];
      }

      if (search.is_online) {
        query.filter['is_online'] = search.is_online[0];
      }
    }
    
    REQUESTS.USERS.GET({ query: JSON.stringify(query) },
      (data) => {
        setData(data.rows);
        setTotal(data.count);
        setLoading(false);
      })
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getUsers();
    }, 500);

    return () => clearTimeout(timeoutId);

  }, [limit, currentPage, search, sort, searchMac]);

  return (
    <div>
      <h1>Users</h1>
      <Button
        onClick={() => {
          setOpen(true);
          setActionType("add")
        }}
        className={'add-button'}
      >
        Add New
      </Button>

      <div style={{ width: 200, marginBottom: 30, height: 30 }}>
        <ValidateMacAddress
          placeholder="Search Mac Address"
          setMac={setSearchMac}
          mac={searchMac}
        />
      </div>
      <Table
        columns={columns}
        dataSource={data}
        onChange={handleTableChange}
        loading={loading}
        pagination={{
          position: ["bottomCenter"],
          current: currentPage,
          total: total,
          pageSize: limit,
          showSizeChanger: true,
        }}
        bordered
        size='small'
        scroll={{ x: '100%' }}
      />
      <UsersModal
        open={open}
        handleCancel={() => {
          setOpen(false);
          setCurrent(null);
        }}
        deviceMac={current}
        actionType={actionType}
        getUsers={getUsers}
      />
      {contextHolder}
    </div>
  )
}
