import React, { useState, useEffect } from "react";

import Message from "./Message";

import { REQUESTS } from "../api/requests";

import styles from "./style/macInput.module.scss";

export default function ValidateMacAddress({
  setMacIsValid = () => { },
  mac = "",
  setMac,
  setDevice = () => { },
  placeholder = "Mac address",
  disabled = false,
}) {
  const [message, setMessage] = useState({
    text: "",
    type: true,
  });

  const validateMacAddress = () => {

    setMessage({
      text: "",
      type: true,
    });

    if (mac?.length === 0) {
      return;
    }

    function callback(data) {
      if (setDevice) {
        setDevice(data);
      }
      setMessage({
        text: "Mac is valid",
        type: true,
      });
      setMacIsValid(true);
    }

    function errorCallback(error) {
      setMacIsValid(false);
      setMessage({
        text: "Mac is not valid",
        type: false,
      });
    }

    REQUESTS.CHECK_DEVICE({ mac }, callback, errorCallback);
  };

  const generateMacFormat = (e) => {
    var mac = e.target.value.replace(/[^0-9a-z]/gi, "");
    var result = "";

    for (var i = 0; i < mac.length; i++) {
      if (i % 2 === 0 && i !== 0) {
        result += ":";
      }
      result += mac[i];
    }
    setMac(result.substring(0, 17));
  };

  useEffect(() => {

    if (mac?.length === 17) {
      validateMacAddress();
    } else {
      setDevice(null)
      setMacIsValid(false);
      setMessage({
        text: "",
        type: false,
      });
    }

  }, [mac]);

  return (
    <div className={styles["custom-mac-input-container"]}>
      <input
        value={mac}
        onChange={generateMacFormat}
        placeholder={placeholder}
        name="mac"
        id="mac"
        className={styles["mac-inp"]}
        disabled={disabled}
      />
      <Message message={message} />
    </div>
  );
}

