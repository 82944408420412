import request from "./request";
import URLS from "./url";
import requestQuery from "./requestQuery";

export const REQUESTS = {
  // a
  // b
  // c
  CHECK_DEVICE: (query, callback, errorCallback) => {
    request("get", URLS.CHECK_DEVICE + requestQuery(query), {}, callback, errorCallback)
  },
  // d
  // e
  // f
  // g
  // h
  // i
  // j
  // k
  // l
  LOGIN: (body, callback, errorCallback) => {
    request("post", URLS.LOGIN, body, callback, errorCallback)
  },
  // m
  // n
  // o
  // p
  PROFILE: {
    GET: (callback) => {
      request("get", URLS.PROFILE, {}, callback)
    },
    UPDATE: (body, callback, errorCallback) => {
      request("put", URLS.PROFILE, body, callback, errorCallback)
    },
  },
  // q
  // r
  // s
  SERVERS: (query,callback) => {
    request("get", URLS.SERVERS+requestQuery(query), {}, callback)
  },
  
  SUBRESELLERS: {
    GET: (query,callback,errorCallback) => {
      request("get", URLS.SUBRESELLERS+requestQuery(query), {}, callback,errorCallback)
    },
    ADD: (body, callback, errorCallback) => {
      request("post", URLS.SUBRESELLERS, body, callback, errorCallback)
    },
    DELETE: (body, callback) => {
      request("delete", URLS.SUBRESELLERS, body, callback)
    },
    UPDATE: (body, callback, errorCallback) => {
      request("put", URLS.SUBRESELLERS, body, callback, errorCallback)
    },
  },
  SUBRESELLERS_SUB: (query,callback,errorCallback) => {
    request("get", URLS.SUBRESELLERS_SUB+requestQuery(query), {}, callback,errorCallback)
  },
  // t
  // u
  USERS: {
    GET: (query,callback) => {
      request("get", URLS.USERS+requestQuery(query), {}, callback)
    },
    DELETE: (body, callback) => {
      request("delete", URLS.USERS, body, callback)
    },
    UPDATE: (body, callback, errorCallback) => {
      request("put", URLS.USERS, body, callback, errorCallback)
    },
    ADD: (body, callback, errorCallback) => {
      request("post", URLS.ATTACH_DEVICE, body, callback, errorCallback)
    },
  }
  // v
  // w
  // x
  // y
  // z
};