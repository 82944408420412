import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Button, Form, Input } from 'antd';

import { LockOutlined, UserOutlined, } from '@ant-design/icons';

import { REQUESTS } from '../../api/requests';

import Message from '../../components/Message';

import styles from './index.module.scss';

export default function Login() {
    const [form] = Form.useForm();

    const [message, setMessage] = useState({ type: false, text: "" })

    const navigate = useNavigate()

    const onFinish = (values) => {
        setMessage({ type: false, text: "" })

        REQUESTS.LOGIN(values, (data) => {
            localStorage.setItem('TOKEN', data);
            navigate('/users');
        }, (error) => {
            setMessage({ type: false, text: error })
        })
    };

    return (
        <div className={styles['login-page']}>
            <h1>Login</h1>
            <Form
                form={form}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onValuesChange={() => setMessage({ type: false, text: "" })}
                className={styles['login-form']}
            >
                <Form.Item
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Username!',
                        },
                    ]}
                >
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Password!',
                        },
                    ]}
                >
                    <Input.Password
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Password"
                    />
                </Form.Item>
                <Message message={message} />
                <Form.Item className={styles['login-form-button']}>
                    <Button type="primary" htmlType="submit" >
                        Log in
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}
