import React, { useEffect, useRef, useState } from 'react';

import { Button, notification } from 'antd';

import { PlusOutlined } from '@ant-design/icons';

import PlaylistForm from '../../components/PlaylistForm';

import ValidateMacAddress from '../../components/ValidateMacAddress';

import { REQUESTS } from '../../api/requests';

import styles from './index.module.scss';
import scrollToBottom from '../../utils/scrollToBottom';

export default function PlaylistUpload() {
  const [mac, setMac] = useState("");
  const [device, setDevice] = useState(null);
  const [macIsValid, setMacIsValid] = useState(false);
  const [servers, setServers] = useState([]);
  const [devicePlaylists, setDevicePlaylists] = useState([])
  const [api, contextHolder] = notification.useNotification();

  const playlistsContainerRef = useRef()

  const getServers = () => {

    let query = {
      pagination: false,
    };

    REQUESTS.SERVERS({ query: JSON.stringify(query) }, (data) => {
      setServers(data);
    })
  };

  const update = (playlist, index) => {

    devicePlaylists[index] = { ...playlist }

    setDevicePlaylists([...devicePlaylists]);
  }

  const addPlaylist = () => {
    devicePlaylists.push({
      action: 'new',
      server_id: null,
      username: "",
      password: "",
    });

    setDevicePlaylists([...devicePlaylists]);

    const el = playlistsContainerRef?.current

    if (el)
      setTimeout(() => {
        scrollToBottom(el)
      }, 1);
  }

  const save = () => {
    const updatedPlaylists = [];
    for (let i = 0; i < devicePlaylists.length; i++) {
      const { username, password, server_id, action, id } = devicePlaylists[i];
      if (username.length && password.length && server_id) {
        updatedPlaylists.push({
          username,
          password,
          server_id,
          action,
          reseller_id: device.reseller_id,
        })

        if (action !== 'new') {
          updatedPlaylists[i].id = id;
        }
      } else {
        if (action !== 'delete') {
          api['error']({
            message: "Playlist Upload",
            description: "Please fill all the fields"
          });
          return;
        }
      }
    }

    const body = {
      device_id: device.id,
      playlists: updatedPlaylists,
    }

    REQUESTS.USERS.UPDATE(body, (data) => {
      api['success']({
        message: "Playlist Upload",
        description: "Updated"
      });
    }, (error) => {
    })
  }

  useEffect(() => {
    if (device && device.playlists) {
      if (device.playlists.length === 0) {
        devicePlaylists.push({
          action: 'new',
          server_id: null,
          username: "",
          password: "",
        });

        setDevicePlaylists([...devicePlaylists]);
      } else {

        const list = device.playlists.map(item => {
          return {
            ...item,
            action: 'update',
          }
        })
        setDevicePlaylists(list)
      }
    };
  }, [device]);

  useEffect(() => {
    getServers();
  }, []);

  return (
    <div>
      <h1>Playlist Upload</h1>
      <div className={styles["note-text"]}>
        <span>Note: </span>
        This page is to upload playlist with mac address</div>
      <div className={styles['upload-playlist-container']}>
        <ValidateMacAddress mac={mac} setMac={setMac} setMacIsValid={setMacIsValid} setDevice={setDevice} />
      </div>
      <div ref={playlistsContainerRef} className={styles['playlist-container']}>
        {
          macIsValid &&
          devicePlaylists && devicePlaylists.map((playlist, index) => {
            if (playlist.action !== 'delete') {
              return <PlaylistForm
                key={playlist.id}
                playlist={playlist}
                update={(playlist) => update(playlist, index)}
                servers={servers}
              />
            }
          })
        }
      </div>
      {macIsValid && <div>
        <Button
          onClick={addPlaylist}
          className={'add-button'}
          size='small'
        >
          <PlusOutlined />
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          onClick={save}
        >
          Save
        </Button>
      </div>
      }
      {contextHolder}
    </div>
  )
}
