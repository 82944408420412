import React, { useEffect, useState } from 'react';

import { Table, Space, Button, Modal, Input } from 'antd';

import { DeleteOutlined, EditOutlined, SearchOutlined,ArrowLeftOutlined } from '@ant-design/icons';

import { REQUESTS } from '../../../api/requests';

import dateFormat from '../../../utils/dateFormat';

import ResellerModal from './ResellerModal';

import styles from "../index.module.scss";

const { confirm } = Modal;

const ids = [];

export default function SubresellersModal({ setSubReseller, subReseller }) {

    const [currentPage, setCurrentPage] = useState(1);

    const [total, setTotal] = useState(0);

    const [limit, setLimit] = useState(10);

    const [open, setOpen] = useState(false);

    const [actionType, setActionType] = useState("add");

    const [editableItem, setEditableItem] = useState(null);

    const [subresellers, setSubresellers] = useState([]);

    const [sort, setSort] = useState(["createdAt", "DESC"]);

    const [search, setSearch] = useState({});

    const [loading, setLoading] = useState(false);

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => {
                return (currentPage - 1) * limit + index + 1
            }
        },
        {
            title: 'Name',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Sub-resellers',
            dataIndex: 'sub_reseller_count',
            key: 'sub_reseller_count',
            sorter: true,
        },
        {
            title: 'Devices',
            dataIndex: 'device_count',
            key: 'device_count',
            sorter: true,
        },
        {
            title: 'Created date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (_text, record) => dateFormat(new Date(record.createdAt)),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                return <Space>
                    <Button
                        type="primary"
                        size='small'
                        onClick={(e) => {
                            e.stopPropagation();
                            handleTableActionChange("edit", record)
                        }}>
                        <EditOutlined /></Button>
                    <Button
                        type="primary"
                        size='small'
                        onClick={(e) => {
                            e.stopPropagation();
                            handleTableActionChange("delete", record)
                        }} danger>
                        <DeleteOutlined />
                    </Button>
                </Space>
            },
        },
    ];

    const handleTableActionChange = (actionType, item) => {
        switch (actionType) {
            case "edit":
                setActionType("edit");
                setOpen(true);
                setEditableItem(item);
                break;
            case "delete":
                confirm({
                    title: 'Do you want to delete?',
                    icon: null,
                    onOk() {
                        REQUESTS.SUBRESELLERS.DELETE({ id: item.id }, () => {
                            // getSubResellers();
                        })
                    }
                });
                break;
            default:
                break;
        }
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setLoading(true);

        setCurrentPage(pagination.current);

        setLimit(pagination.pageSize);

        if (sorter.field) {
            setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
        }
    }

    const getSubResellers = () => {
        setLoading(true);

        const query = {
            page: currentPage,
            limit,
            count: total,
            sort,
        };

        if (search.length) {
            query['search'] = {
                username: search,
                email: search,
            }
        }

        if (subReseller) {

            REQUESTS.SUBRESELLERS_SUB({ id: subReseller.id, query: JSON.stringify(query) },
                (data) => {
                    setLoading(false);
                    setSubresellers(data.rows);
                    setTotal(data.count);
                },
                (data) => setLoading(false)
            );

        }
    }

    const backFunction = () => { 
        if (ids.length > 0) {
            setSubReseller(ids[ids.length - 1]);
        } else {
            setSubReseller(false);
         }
    }

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            getSubResellers();
        }, 500);

        return () => clearTimeout(timeoutId);
    }, [limit, currentPage, sort, search])

    useEffect(() => {
        getSubResellers();
    }, [subReseller])

    return (
        <Modal
            open={subReseller}
            title={<h3><ArrowLeftOutlined onClick={backFunction} />{subReseller?.username}{" "}Sub-resellers</h3>}
            onCancel={() => {
                setSubReseller(false);
                setCurrentPage(1);
                setLimit(10);
            }}
            footer={null}
            destroyOnClose={false}
            width={1000}
        >
            <div style={{ width: 200,marginBottom:10 }}>
                <Input
                    placeholder='Search'
                    suffix={<SearchOutlined />}
                    const={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>
            <Table
                columns={columns}
                dataSource={subresellers}
                onChange={handleTableChange}
                pagination={{
                    position: ["bottomCenter"],
                    current: currentPage,
                    total: total,
                    pageSize: limit,
                    showSizeChanger: true,
                }}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {
                            ids.push(record)
                            setSubReseller(record)
                        },
                    };
                }}
                loading={loading}
                rowClassName={styles['table-row-style']}
                bordered
                size='small'
                scroll={{ x: '100%' }}
            />
            <ResellerModal
                open={open}
                handleCancel={() => {
                    setOpen(false);
                    setActionType("add");

                    if (editableItem) {
                        setEditableItem(null);
                    }
                }}
                editableItem={editableItem}
                actionType={actionType}
                getSubResellers={getSubResellers}
            />
        </Modal>
    )
}