import CONSTANTS from "../config";

const URLS = {
    // a
    ATTACH_DEVICE: `${CONSTANTS.API_HOST}reseller/devices/attach`,
    // b
    // c
    CHECK_DEVICE: `${CONSTANTS.API_HOST}reseller/devices/check_device`,
    // d
    // e
    // f
    // g
    // h
    // i
    // j
    // k
    // l
    LOGIN: `${CONSTANTS.API_HOST}reseller/login`,
    // m
    // n
    // o
    // p
    PROFILE: `${CONSTANTS.API_HOST}reseller/profile`,
    // q
    // r
    // s
    SERVERS: `${CONSTANTS.API_HOST}reseller/servers`,
    SUBRESELLERS: `${CONSTANTS.API_HOST}reseller/sub_resellers`,
    SUBRESELLERS_SUB: `${CONSTANTS.API_HOST}reseller/sub_resellers/by_id`,
    // t
    // u
    USERS: `${CONSTANTS.API_HOST}reseller/devices`,
    // v
    // w
    // x
    // y
    // z
};

export default URLS;
