import React, { useState } from 'react';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
  LogoutOutlined
} from '@ant-design/icons';

import { Layout, Menu, Button, Avatar, Dropdown,Modal } from 'antd';

import styles from './index.module.scss';

const { Header, Sider, Content } = Layout;

const { confirm } = Modal;

export default function Dashboard() {
  const navigate = useNavigate();

  const location = useLocation();

  const currentPath = location.pathname.replace("/", "");

  const [collapsed, setCollapsed] = useState(false);

  const [selectedMenuItem, setSelectedMenuItem] = useState(currentPath);

  return (
    <div>
      <Layout style={{ minHeight: "100vh" }}>
        <Sider trigger={null} collapsible collapsed={collapsed}>
          <div className="demo-logo-vertical" style={{ height: 150 }} />
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={[selectedMenuItem]}
            items={[
              {
                key: 'users',
                icon: <VideoCameraOutlined />,
                label: 'Users',
              },
              {
                key: 'playlistUpload',
                icon: <VideoCameraOutlined />,
                label: 'Playlist Upload',
              },
              {
                key: 'profile',
                icon: <UserOutlined />,
                label: 'Profile',
              },
              {
                key: 'subresellers',
                icon: <UploadOutlined />,
                label: 'Sub-resellers',
              },
            ]}
            onClick={({ _item, key }) => {
              setSelectedMenuItem(key);
              navigate(key);
            }}
          />
        </Sider>
        <Layout>
          <Header
            style={{
              padding: 0,
              // background: colorBgContainer,
            }}
          >
            <div className={styles['header-content']}>
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  fontSize: '16px',
                  width: 64,
                  height: 64,
                  color: "#fff"
                }}
              />
              <Dropdown
                menu={{
                  items:[
                    {
                      label: 'Logout',
                      key: 'logout',
                      icon: <LogoutOutlined />,
                    },
                  ],
                  onClick: ({ key }) => {
                    if (key === 'logout') {
                      confirm({
                        title: 'Do you want to logout?',
                        icon: null,
                        onOk() {
                          localStorage.removeItem('TOKEN');
                          navigate('/login');
                        },
                       
                      });
                    }
                  },
                }}
                trigger={['click']}
              >
                <Avatar icon={<UserOutlined />} className={styles['avatar']} />
              </Dropdown>
            </div>
          </Header>
          <Content
            style={{
              margin: '24px 16px',
              padding: 0,
              minHeight: 280,
              // background: colorBgContainer,
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </div>
  )
}
