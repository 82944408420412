import React, { useState, useEffect } from 'react';

import { Button, Form, Modal, Input, notification } from 'antd';

import Message from '../../../components/Message';

import { REQUESTS } from '../../../api/requests';

export default function ResellerModal({ open, handleCancel, actionType = "add", editableItem, getResellers }) {
    const [form] = Form.useForm();

    const [message, setMessage] = useState({ type: false, text: "" });

    const [loading, setLoading] = useState(false);

    const [api, contextHolder] = notification.useNotification();

    const onFinish = (values) => {
        setLoading(true);

        const callback = () => {
            setLoading(false);
            getResellers();
            handleCancel();
            api['success']({
                message: "Sub-resellers",
                description: "Updated"
            });
        };

        const errorCallback = (err) => {
            setLoading(false);
            setMessage({
                type: false,
                text: err,
            });
        };

        if (actionType === "add") {
            const body = {
                ...values,
            };

            REQUESTS.SUBRESELLERS.ADD(body, callback, errorCallback)
        } else {
            const body = {
                username: values.username,
                email: values.email,
                id: editableItem?.id,
            };

            if (values.password) {
                body.password = values.password;
                body.confirm_password = values.confirm_password;
            }

            REQUESTS.SUBRESELLERS.UPDATE(body, callback, errorCallback)
        }
    }

    useEffect(() => {
        if (!open) {
            form.resetFields();
            setMessage({ type: false, text: "" })
        } else {
            if (editableItem) {
                form.setFieldsValue({
                    username: editableItem.username,
                    email: editableItem.email,
                })
            }
        }
    }, [open, editableItem])

    return (<>
        <Modal
            open={open}
            title={actionType === "edit" ? <h3>Edit Sub-reseller</h3> : <h3>Add Sub-reseller</h3>}
            onCancel={handleCancel}
            footer={null}
        >
            <Form
                form={form}
                layout='vertical'
                style={{
                    maxWidth: 600,
                }}
                onValuesChange={() => setMessage(null)}
                onFinish={onFinish}
                autoComplete="off"
                className='form'
            >
                <Form.Item
                    label="Username"
                    name="username"
                >
                    <Input placeholder='Username' />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid Email!',
                        },
                        {
                            required: true,
                            message: 'Please input valid Email!',
                        },
                    ]}
                >
                    <Input placeholder='Email' />
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                        {
                            min: 8,
                            message: 'Password must be at least 8 characters long',
                        },
                    ]}
                >
                    <Input placeholder='Password' />
                </Form.Item>

                {actionType === "edit" && <Form.Item
                    label="Confirm Password"
                    name="confirm_password"
                    dependencies={['password']}
                    rules={[
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The new password that you entered do not match!'));
                            },
                        }),
                    ]}
                >
                    <Input placeholder='Confirm Password' />
                </Form.Item>}

                <Message message={message} style={{ display: "flex", justifyContent: "center" }} />

                <Form.Item className='save-button'>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                    >
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
        {contextHolder}
    </>
    )
}
