import { Navigate, Route, Routes } from 'react-router-dom';

import Dashboard from './layout';

import Login from './pages/login';
import Profile from './pages/profile';
import Subresellers from './pages/subresellers';
import Users from './pages/users';
import PlaylistUpload from './pages/playlistUpload';

import "./index.scss";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Navigate replace to="login" /> } />
        <Route path='/login' element={<Login />} />
        
        <Route path="/" element={<Dashboard />} >
          <Route path='profile' element={<Profile />} />
          <Route path='users' element={<Users />} />
          <Route path='subresellers' element={<Subresellers />} />
          <Route path="playlistUpload" element={<PlaylistUpload/>}/>
        </Route>
     </Routes>
    </div>
  );
}

export default App;
