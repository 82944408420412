import React, { useState, useEffect, useRef } from 'react';

import { Button, Form, Modal, notification } from 'antd';

import className from 'classnames';

import { PlusOutlined } from '@ant-design/icons';

import Message from '../../../components/Message';
import ValidateMacAddress from '../../../components/ValidateMacAddress';
import PlaylistForm from '../../../components/PlaylistForm';

import { REQUESTS } from '../../../api/requests';

import styles from "../index.module.scss";
import scrollToBottom from '../../../utils/scrollToBottom';

export default function UsersModal({ open, handleCancel, actionType, deviceMac, getUsers }) {
    const [message, setMessage] = useState({ type: false, text: "" });

    const [mac, setMac] = useState("");

    const [device, setDevice] = useState(null);

    const [devicePlaylists, setDevicePlaylists] = useState([])

    const [api, contextHolder] = notification.useNotification();

    const [servers, setServers] = useState([]);

    const playlistContainer = useRef()

    const getServers = () => {
        let query = {
            pagination: false,
        };

        REQUESTS.SERVERS({ query: JSON.stringify(query) }, (data) => {
            setServers(data);
        })
    };

    const update = (playlist, index) => {

        devicePlaylists[index] = { ...playlist }

        setDevicePlaylists([...devicePlaylists]);
    }

    const addPlaylist = () => {
        devicePlaylists.push({
            action: 'new',
            server_id: null,
            username: "",
            password: "",
        });

        setDevicePlaylists([...devicePlaylists]);

        scrollBottom();
    }

    const editPlaylist = (updatedPlaylists) => {
        const body = {
            device_id: device.id,
            playlists: updatedPlaylists,
        }

        REQUESTS.USERS.UPDATE(body, (data) => {
            handleCancel()
            getUsers();
            api['success']({
                message: "Users",
                description: "Updated"
            });
        }, (error) => {
            setMessage({ type: false, text: error })
        })
    }

    const scrollBottom = () => {
        setTimeout(() => {
            if (playlistContainer && playlistContainer.current) {
                const el = playlistContainer.current;
                scrollToBottom(el)
            };
        }, 1);
    }

    const save = () => {
        setMessage({ type: false, text: "" });

        const updatedPlaylists = [];

        for (let i = 0; i < devicePlaylists.length; i++) {
            const { username, password, server_id, action, id, reseller_id } = devicePlaylists[i];
            if (username && password && server_id) {
                updatedPlaylists.push({
                    username,
                    password,
                    server_id,
                    action,
                    reseller_id: reseller_id || null
                })

                if (action !== 'new') {
                    updatedPlaylists[i].id = id;
                }
            }
        }

        if (actionType === 'add') {
            REQUESTS.USERS.ADD({ id: device.id, },
                (data) => {
                    editPlaylist(updatedPlaylists);

                },
                (error) => {
                    setMessage({ type: false, text: error })

                })
        } else {
            editPlaylist(updatedPlaylists)
        }
    }

    useEffect(() => {
        if (open) {
            if (deviceMac) {
                setMac(deviceMac);
            }
        } else {
            setMac("");
            setDevice(null);
            setDevicePlaylists([]);
        }
    }, [open, deviceMac]);

    useEffect(() => {
        if (device && device.playlists) {
            const list = device.playlists.map(item => {
                return {
                    ...item,
                    action: 'update',
                }
            })
            setDevicePlaylists(list)
        }
    }, [device]);

    useEffect(() => {
        if (open) getServers();
    }, [open]);
    return (<>
        <Modal
            open={open}
            title={actionType === "edit" ? <h3>Edit User</h3> : <h3>Add User</h3>}
            onCancel={handleCancel}
            footer={null}
            destroyOnClose={true}
            bodyStyle={{ height: '449px', }}
        >
            <div>
                <ValidateMacAddress
                    mac={mac}
                    setMac={setMac}
                    setDevice={setDevice}
                    disabled={actionType === "edit"}
                />
            </div>
            <div
                className={className(styles['playlists-form-container'])}
                ref={playlistContainer}>
                {
                    devicePlaylists && devicePlaylists.map((playlist, index) => {
                        if (playlist.action !== 'delete') {
                            return <PlaylistForm
                                key={playlist.id}
                                open={open}
                                playlist={playlist}
                                update={(playlist) => update(playlist, index)}
                                servers={servers}
                            />
                        }
                    })
                }
            </div>

            <div className={styles['add-btn-container']}>
                <Button
                    onClick={addPlaylist}
                    className={'add-button'}
                    size='small'
                >
                    <PlusOutlined style={{ color: "green" }} />
                </Button>
            </div>

            <Message message={message} />

            <Form.Item className={"save-button"}>
                <Button
                    type="primary"
                    htmlType="submit"
                    onClick={save}
                    disabled={!device}
                >
                    Save
                </Button>
            </Form.Item>
        </Modal>
        {contextHolder}
    </>
    )
}
