import React, { useEffect, useState } from 'react';

import { Form, Input, Select, Button, Flex } from 'antd';

import { DeleteOutlined } from '@ant-design/icons';

import styles from "./style/playlistForm.module.scss";

export default function PlaylistForm({ update, playlist = null, servers = [] }) {
    const [form] = Form.useForm();

    const [selectedSever, setSelectedSever] = useState(null);

    const onBlur = () => {
        const fieldsValues = form.getFieldsValue();

        const updatedPlaylist = {
            ...playlist,
            ...fieldsValues,
            "server_id": selectedSever
        }

        if (update) {
            update(updatedPlaylist);
        }
    }

    useEffect(() => {
        if (playlist.username && playlist.password && playlist.server_id) {
            form.setFieldsValue({
                username: playlist.username,
                password: playlist.password,
            });
            setSelectedSever(playlist.server_id)
        }
    }, [playlist]);

    useEffect(() => {
        if (!playlist.server_id && servers && servers[0]) {
            setSelectedSever(servers[0].id)
        }
    }, [servers, playlist])

    return (
        <div className={styles['container']}>
            <div className={styles['remove-button']}>
                <Button
                    onClick={() => {
                        playlist.action = "delete";
                        form.resetFields();
                        if (update) {
                            update(playlist);
                        }
                    }}
                    size='small'
                    danger
                >
                    <DeleteOutlined />
                </Button>
            </div>
            <Form
                form={form}
                layout='vertical'
                autoComplete="off"
            >
                <div className={styles["form-content"]}>
                    <div>
                        <label>DNS</label>
                        <Select
                            name="server_id"
                            value={selectedSever}
                            style={{
                                width: '100%',
                                margin: '10px 0',
                            }}
                            onBlur={onBlur}
                            onChange={(value) => {
                                playlist.server_id = value;
                                setSelectedSever(value)
                                if (update) {
                                    update(playlist);
                                }
                            }}
                            options={servers && servers.map((server) => ({ value: server.id, label: server.name }))}
                        />
                    </div>
                    <Form.Item
                        label="Username"
                        name="username"
                        style={{ marginBottom: 10 }}
                        rules={[{ required: true, message: 'Please input username!' }]}
                    >
                        <Input
                            placeholder='Username'
                            onBlur={onBlur}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{ required: true, message: 'Please input password!' }]}
                        style={{ marginBottom: 10 }}
                    >
                        <Input
                            placeholder='Password'
                            onBlur={onBlur}
                        />
                    </Form.Item>
                </div>
            </Form>
        </div>
    )
}
