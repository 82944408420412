import React, { useEffect, useState } from 'react';

import { Form, Input, Button, notification } from 'antd';

import Message from '../../components/Message';

import { REQUESTS } from '../../api/requests';

export default function Profile() {
  const [form] = Form.useForm();

  const [message, setMessage] = useState({ type: false, text: "" });

  const [loading, setLoading] = useState(false);

  const [api, contextHolder] = notification.useNotification();

  const getProfile = () => {
    REQUESTS.PROFILE.GET((data) => {
      form.setFieldsValue({
        username: data.username,
        email: data.email,
      });
    })
  }

  const onFinish = (values) => {
    setMessage({ type: false, text: "" });
    
    setLoading(true);

    const { username, email, password, confirm_password } = values;

    const body = {
      username,
      email
    };

    if (password) {
      body['password'] = password;
      body['confirm_password'] = confirm_password;
    }

    REQUESTS.PROFILE.UPDATE(body, (data) => {
      getProfile();
      setLoading(false);
      
      api['success']({
        message: "Profile",
        description: "Updated"
      });

    }, (err) => {
      setLoading(false);
      setMessage({ type: false, text: err })
    })
  }

  useEffect(() => {
    getProfile()
  }, [])

  return (
    <div>
      <h1>
        Profile
      </h1>
      <Form
        form={form}
        layout='vertical'
        style={{
          maxWidth: 600,
        }}
        onValuesChange={() => setMessage({ type: false, text: "" })}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[
            {
                required: true,
                message: 'Please input Username!',
            },
        ]}
        >
          <Input placeholder='Username' />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
                type: 'email',
                message: 'The input is not valid Email!',
            },
            {
                required: true,
                message: 'Please input valid Email!',
            },
        ]}
        >
          <Input placeholder='Email' />
        </Form.Item>

        <Form.Item
          label="Password (leave as blank if you don't want to change)"
          name="password"
          rules={[
            {
              min: 8,
              message: 'Password must be at least 8 characters long',
            },
          ]}
        >
          <Input.Password placeholder='Password' />
        </Form.Item>

        <Form.Item
          label="Confirm Password (leave as blank if you don't want to change)"
          name="confirm_password"
          dependencies={['password']}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The new password that you entered do not match!'));
              },
            }),
          ]}
        >
          <Input.Password placeholder='Confirm Password' />
        </Form.Item>
        <Message message={message} />
        <Form.Item  className='save-button'>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
      {contextHolder}
    </div>
  )
}
